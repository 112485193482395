export const columns = [
  {
    title: "N°",
    key: "index",
    width: "4%",
    slots: { customRender: "rangepages" }
  },
  {
    title: "ID Actividad",
    dataIndex: "idactividadhis",
    key: "idactividadhis"
  },
  {
    title: "Nombre",
    dataIndex: "denominacion",
    key: "denominacion"
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" }
  }
];
